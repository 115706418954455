/* global $ */
import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import hljs from 'highlight.js';

function ChatWindow({ conversationMessages }) {
  useEffect(() => {

    // Ensure jQuery is loaded
    if (window.$) {
      $('.chat-box-one').slimScroll({
        height: '800px'
      });

      setTimeout(() => {
        $('.chat-box-one').slimScroll({ scrollTo : $('.chat-box-one').prop('scrollHeight') + 'px'  });
      }, 1000);


    } else {
      console.error('jQuery is not loaded');
    }
  }, []);


  const renderMessageContent = useCallback((message) => {
    const codeBlockRegex = /```(\w+)?\s*([\s\S]*?)```/g; // Detects fenced code blocks with language specifier
  
    let match;
    let lastIndex = 0;
    const elements = [];
  
    // Function to handle inline elements (inline code and bold text) within text segments
    const renderInlineElements = (text) => {
      const parts = text.split(/(\*\*[^*]*\*\*|`[^`]*`)/g); // Split the text by bold double asterisks or inline code backticks
  
      return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          const boldContent = part.slice(2, -2);
          return <strong key={index}>{renderInlineElements(boldContent)}</strong>; // Recursively render the content inside the bold text
        } else if (part.startsWith('`') && part.endsWith('`')) {
          return <code key={index} style={{ backgroundColor: '#f4f4f4', padding: '2px 4px', borderRadius: '4px', fontFamily: 'monospace' }}>{part.slice(1, -1)}</code>;
        }
        return part; // Return the text as is if it's not code or bold
      });
    };
  
    // First, handle fenced code blocks
    while ((match = codeBlockRegex.exec(message))) {
      // Add text before code block
      if (lastIndex !== match.index) {
        elements.push(<span key={`text-${lastIndex}`}>{renderInlineElements(message.substring(lastIndex, match.index))}</span>);
      }
  
      const code = match[2].trim();
      const detectedLanguage = hljs.highlightAuto(code).language;
  
      // Add syntax-highlighted code block
      elements.push(
        <SyntaxHighlighter key={`code-${match.index}`} language={detectedLanguage} style={vscDarkPlus}>
          {code}
        </SyntaxHighlighter>
      );
      lastIndex = codeBlockRegex.lastIndex;
    }
  
    // Add any remaining text after the last code block
    if (lastIndex < message.length) {
      elements.push(<span key={`text-${lastIndex}`}>{renderInlineElements(message.substring(lastIndex))}</span>);
    }
  
    return elements;
  }, []);
  
  

  const sortedMessages = useMemo(() => {
    return Object.entries(conversationMessages).sort((a, b) => a[0].localeCompare(b[0]));
  }, [conversationMessages]);

  function formatDate(date) {
    return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
}

  // Component render JSX
  return (


    <div className="box-body mb-30">
        <div className="chat-box-one">
          {
            sortedMessages.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  {value[1].type === "bot" ? (
                    <div className="card d-inline-block mb-3 float-start me-2 no-shadow bg-lighter max-w-p80">
                      <div className="position-absolute pt-1 pe-5 r-0">
                        <span className="text-muted">{formatDate(value[1].timestamp)}</span>
                      </div>
                      <div className="card-body">
                        <div className="d-flex flex-row pb-2">
                          <img src={`/assets/images/${value[1].botAlias}.png`} alt={`${value[1].botName}`} className="avatar me-10" />
                          <div className="d-flex flex-grow-1">
                            <div className="m-2 ps-0 align-self-center d-flex flex-column flex-lg-row justify-content-between">
                              <div>
                                <p className="mb-0 fs-16 text-dark">{value[1].botName}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="chat-text-left ps-55">
                          <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{renderMessageContent(value[1].message)}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card d-inline-block mb-3 float-end me-2 bg-primary w-p80">
                      <div className="position-absolute pt-1 ps-5 l-0">
                        <span>{formatDate(value[1].timestamp)}</span>
                      </div>
                      <div className="card-body">
                        <div className="d-flex flex-row pb-2">
                          <div className="d-flex flex-grow-1 justify-content-end">
                            <div className="m-2 ps-0 align-self-center d-flex flex-column flex-lg-row justify-content-between">
                              <div>
                                <p className="mb-0 fs-16"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="chat-text-left pe-50">
                          <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{value[1].message}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="clearfix"></div>
                </React.Fragment>
              )
            })}

        </div>
    </div>

  );
}

ChatWindow.propTypes = {
  conversationMessages: PropTypes.object.isRequired
};


export default ChatWindow;