import React from 'react';
import { Navigate  } from 'react-router-dom';

const authenticate = ({ children, redirectTo }) => {
    let token = localStorage.getItem("token");
    if (!token) {
        // Redirect to the login or specified path if the cookie does not exist
        return <Navigate to={redirectTo} />;
    }

    return children; // Render children if the cookie exists
};

export default authenticate;