import React, { useState, useEffect } from 'react';
import { nerdleGetBots } from '../services/nerdle_api';
import PropTypes from 'prop-types';

function BotSelector({ onStartChat }) {
    const [bots, setBots] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBots, setSelectedBots] = useState([]);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await nerdleGetBots();
                setBots(response.bots);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleStartChat = (event) => {
        onStartChat(selectedBots, message);
    };

    const handleBotClick = (event) => {
        const { id, checked, value } = event.target; // Get id and checked status from the event
        setSelectedBots(prevState => ({
            ...prevState,
            [id]: {
                checked: checked,
                botName: value
            }
        }));
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div id="bot-selector" className="box">
            <div className="box-header with-border">
                <h4 className="box-title">Create your bot team and chat!</h4>
                <div className="box-controls pull-right">
                    <div className="box-header-actions">
                        <div className="lookup lookup-sm lookup-right d-none d-lg-block">
                            <input type="text" name="s" placeholder="Search" onChange={handleSearchChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="box-body">
                <div className="mailbox-messages inbox-bx">
                    <div className="table-responsive">
                        <table className="table table-hover table-striped">
                            <tbody>
                                {bots && bots.map(bot => (
                                    <tr key={bot.botId} id={`bot-${bot.botId}`} className={searchTerm && !bot.botName.toLowerCase().includes(searchTerm) ? 'd-none' : ''}>
                                        <td>
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={`chk-${bot.botId}`}
                                                    value={bot.botAlias + "-" + bot.botName}
                                                    checked={!!selectedBots[`chk-${bot.botId}`]?.checked} // Use double negation to ensure boolean value
                                                    onChange={handleBotClick}
                                                />
                                                <label htmlFor={`chk-${bot.botId}`}></label>
                                            </div>
                                        </td>
                                        <td className="mailbox-star">
                                            <button className="no-border no-background-color">
                                                <i className="fa fa-star text-yellow"></i>
                                            </button>
                                        </td>
                                        <td>
                                            <img className="rounded-border" width="200" src={`/assets/images/${bot.botAlias}.png`} alt={`${bot.botName}`} />
                                        </td>
                                        <td>
                                            <p className="mailbox-name mb-0 fs-16 fw-600">{bot.botName}</p>
                                            <p className="mailbox-subject" href="#">{bot.botDescription}</p>
                                        </td>
                                        <td className="mailbox-attachment"></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="d-md-flex d-block justify-content-between align-items-center">
                    <textarea 
                        className="form-control b-0 py-10" 
                        type="text" 
                        placeholder="Say something..." 
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleStartChat();
                            } else if (event.key === 'Enter' && event.shiftKey) {
                                event.preventDefault();
                                setMessage(message + '\n');
                            }
                        }}
                        value={message}
                        onChange={handleMessageChange} 
                    />
                    <div className="d-flex justify-content-between align-items-center mt-md-0 mt-30">
                        <button type="button" className="waves-effect waves-circle btn btn-circle me-10 btn-outline-secondary">
                            <i className="fa fa-paperclip"></i>
                        </button>
                        <button type="button" className="waves-effect waves-circle btn btn-circle btn-primary" onClick={handleStartChat}>
                            <i className="mdi mdi-send"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Type checking for props
BotSelector.propTypes = {
    onStartChat: PropTypes.func.isRequired
};


export default BotSelector;