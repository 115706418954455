/* global $ */
import React, { useEffect } from 'react';

function ParticipatingBots({ currentBots }) {


    useEffect(() => {
        // Ensure jQuery is loaded
        if (window.$) {
            $('#bot-listing').slimScroll({
                height: '200px'
            });
        } else {
            console.error('jQuery is not loaded');
        }
    }, []);

    // Component render JSX
    return (
        <div className="contact-bx">
            <div className="box-header with-border">
                <h4 className="box-title">Participating Bots</h4>
            </div>
            <div className="media-list media-list-hover" id="bot-listing">
                {currentBots && currentBots.map(bot => (
                    <div key={bot.botId} className="media py-10 px-0 align-items-center">
                        <span className="avatar avatar-lg" href="#">
                            <img src={`/assets/images/${bot.botAlias}.png`} alt={bot.Name} />
                        </span>
                        <div className="media-body">
                            <p className="fs-16">
                                {bot.botName}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ParticipatingBots;