import React, { useEffect, useState } from 'react';
import { nerdleLogin } from '../services/nerdle_api';
import { useNavigate } from 'react-router-dom';
//import './MyComponent.css'; // Style import


function Login() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        // Add class when component mounts
        document.body.classList.add('bg-img');
        document.body.classList.remove('light-skin');
        document.body.classList.remove('fixed');
        document.body.classList.remove('sidebar-collapse');
        document.body.style.backgroundImage = "url('/assets/images/auth-bg/bg-1.jpg')";
        document.getElementById("root").style.height = "100vh";
        document.getElementById("root").classList.add('container');

        // Cleanup function to remove class when component unmounts
        return () => {
            document.body.classList.remove('bg-img');
            document.body.classList.add('light-skin');
            document.body.classList.add('fixed');
            document.body.classList.add('sidebar-collapse');
            document.body.style.backgroundImage = '';
            document.getElementById("root").style.height = "";
            document.getElementById("root").classList.remove('container');
            document.getElementById("root").classList.remove('h-p100');
        };
    }, []);

    const handleLoginFormChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        try {
            setShowAlert(false);
            let response = await nerdleLogin(formData);

            if (response) {
                localStorage.setItem('token', response);
                navigate("/", { replace: true });
            }
            else setShowAlert(true);
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    // Component render JSX
    return (
        <div className="row align-items-center justify-content-md-center h-p100">

            <div className="col-12">
                <div className="row justify-content-center g-0">
                    <div className="col-lg-5 col-md-5 col-12">
                        <div className="bg-white rounded10 shadow-lg">
                            <div className="content-top-agile p-20 pb-0">
                                <h2 className="text-primary">Let's Get Started</h2>
                                <p className="mb-0">Sign in to continue to Nerdle.</p>
                            </div>
                            <div className="p-40">
                                <form id="login-form" onSubmit={handleLoginSubmit}>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text bg-transparent"><i className="ti-user"></i></span>
                                            <input type="text" className="form-control ps-15 bg-transparent" name="email" placeholder="Email" value={formData.email} onChange={handleLoginFormChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text  bg-transparent"><i className="ti-lock"></i></span>
                                            <input type="password" className="form-control ps-15 bg-transparent" name="password" placeholder="Password" value={formData.password} onChange={handleLoginFormChange} />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-6">
                                            <div className="checkbox">
                                                <input type="checkbox" id="basic_checkbox_1" />
                                                <label htmlFor="basic_checkbox_1">Remember Me</label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="fog-pwd text-end">
                                                <a href="/forgotpassword" className="hover-warning"><i className="ion ion-locked"></i> Forgot pwd?</a><br />
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" className="btn btn-danger mt-10">SIGN IN</button>
                                        </div>
                                    </div>
                                    <div id="bad-login" className={`row ${showAlert ? '' : 'd-none'}`}>
                                        <div className="col-12 text-center">
                                            <div className="alert alert-danger" role="alert">
                                                Invalid username or password.
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-center">
                                    <p className="mt-15 mb-0">Don't have an account? <a href="auth_register.html" className="text-warning ms-5">Sign Up</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <p className="mt-20 text-white">- Sign With -</p>
                            <p className="gap-items-2 mb-20">
                                <a className="btn btn-social-icon btn-round btn-facebook" href="/facebook"><i className="fa fa-facebook"></i></a>
                                <a className="btn btn-social-icon btn-round btn-twitter" href="/twitter"><i className="fa fa-twitter"></i></a>
                                <a className="btn btn-social-icon btn-round btn-instagram" href="/instagram"><i className="fa fa-instagram"></i></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;