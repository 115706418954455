/* global $ */

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import MainHeader from "../modules/MainHeader";
import DashboardLeftMenu from "../modules/DashboardLeftMenu";
import ChatListing from "../modules/ChatListing";
import ParticipatingBots from "../modules/ParticipatingBots";
import ChatWindow from "../modules/ChatWindow";
import ChatWindowHeader from "../modules/ChatWindowHeader";
import ChatWindowFooter from "../modules/ChatWindowFooter";
import BotSelector from "../modules/BotSelector";

import { nerdleSetupSocketConnection, nerdleSendSocketMessage, nerdleCloseSocketConnection } from "../services/nerdle_api";
import { nerdleGetChat } from '../services/nerdle_api';

function Dashboard() {
    const [showChatWindow, setShowChatWindow] = useState(false);
    const [conversationMessages, setConversationMessages] = useState({});
    const [currentChat, setCurrentChat] = useState(null);
    const [currentBots, setCurrentBots] = useState([]);
    const [chats, setChats] = useState([]);

    const { id } = useParams();
    const navigate = useNavigate();

    const handlerSocketChatRef = useRef();
    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) {
            return;
        }
        hasRun.current = true;
        document.getElementById("root").classList.add('wrapper');

        nerdleSetupSocketConnection({
            response: handleSocketResponse,
            error: handleSocketError,
            chat: handlerSocketChatRef.current
        });

        if (id) {
            const fetchData = async () => {
                try {
                    const response = await nerdleGetChat(id);

                    if (!response) {
                        setShowChatWindow(false);
                        navigate('/');
                        return;
                    }

                    setCurrentChat(response.chat);
                    let messages = {};
                    let bots = {};

                    for (let count = 0; count < response.messages.length; count++) {
                        const message = response.messages[count];
                        let botTemplateId = count.toString().padStart(10, "0") + (message?.botId ? "_bot" : "_user");

                        messages[botTemplateId] = {
                            type: (message?.botId ? "bot" : "user"),
                            message: message.message,
                            timestamp: new Date(message.createdDate),
                            botAlias: message?.botAlias,
                            botName: message?.botName,
                        };

                        if (message?.botId) {
                            if (!bots[message.botId]) bots[message.botId] = {
                                botId: message.botId,
                                botAlias: message.botAlias,
                                botName: message.botName
                            }
                        }
                    }

                    let activeBots = [];
                    for (const botId in bots) {
                        activeBots.push(bots[botId]);
                    }

                    setCurrentBots(activeBots);
                    setConversationMessages(messages);

                } catch (error) {
                    navigate('/');
                }
            };

            fetchData();

            setShowChatWindow(true);
        }

        // Cleanup function to remove class when component unmounts
        return () => {
            nerdleCloseSocketConnection();
            document.getElementById("root").classList.remove('wrapper');
        };
    }, [id, navigate]);

    const updateCurrentChat = (chat) =>{
        let allChats = [...chats];
        const index = allChats.findIndex(obj => obj["chatId"] === chat.chatId);
        allChats[index] = chat;
        setChats(allChats);
        setCurrentChat(chat);
    }

    const updateChatListing = (chats) => {
        setChats(chats);
    }

    const responseMessages = (message) => {
        const activeBots = {};
        for (const bot of currentBots) {

            activeBots["chk-" + bot.botId] = {
                checked: true,
                botName: bot.botAlias + "-" + bot.botName
            }
        }

        sendChat(activeBots, message);
    }

    const sendChat = (bots, message) => {
        const activeBots = [];
        const chat = {}
        let messages = { ...conversationMessages };
        const now = new Date();

        if (!showChatWindow) {
            setShowChatWindow(true);
            messages = {};
            setCurrentChat(null);
        }

        let messageCount = Object.keys(messages).length;

        for (const bot in bots) {
            if (bots[bot].checked) {
                let botParts = bots[bot].botName.split("-");
                activeBots.push({
                    botId: bot.replace("chk-", ""),
                    botAlias: botParts[0],
                    botName: botParts[1]
                });
            }
        }

        setCurrentBots(activeBots);

        chat.bots = [];
        chat.message = message;
        if (currentChat) chat.chatId = currentChat.chatId;

        messageCount += 1;
        messages[messageCount.toString().padStart(10, "0") + "_user"] = {
            type: "user",
            message: message,
            timestamp: now
        };

        for (let count = 0; count < activeBots.length; count++) {
            messageCount += 1;

            let botTemplateId = messageCount.toString().padStart(10, "0") + "_bot";
            messages[botTemplateId] = {
                type: "bot",
                botAlias: activeBots[count].botAlias,
                botName: activeBots[count].botName,
                message: "",
                timestamp: now
            };

            chat.bots.push({
                templateId: botTemplateId,
                botId: activeBots[count].botId
            })
        }

        setConversationMessages(messages);
        nerdleSendSocketMessage("query", chat);

        if (window.$) {
            $('.chat-box-one').slimScroll({ scrollTo: 'bottom' });
        }
    }

    /**************************** Sockets ****************************/
    handlerSocketChatRef.current = (data) => {
        let previousChatState = null;
        setCurrentChat(prevChat => {
            previousChatState = prevChat;
            return data
        });
        setChats(prevChats => { 
            if(!previousChatState)
                return [data, ...prevChats];
            else return prevChats;
        });
    }

    const handleSocketResponse = (data) => {
        let firstCall = true;

        setConversationMessages(prevMessages => {
            const messages = { ...prevMessages };

            if (!firstCall) return messages;
            firstCall = false;
            messages[data.messageId].message += data.dataChunk;

            return messages;
        });
    }

    const handleSocketError = (data) => {
        console.log("error", data);
    }

    /**************************** Styles ****************************/
    const styleWrapper = {
        height: "auto",
        minHeight: "100%"
    };

    /**************************** Render ****************************/
    return (
        <div className="wrapper" style={styleWrapper}>
            <MainHeader />
            <div className="content-wrapper">
                <div className="container-full">
                    <section className="left-block content fixed-left-block bg-white">
                        <a href="/" className="btn btn-danger w-p100 mb-30">Start a Chat</a>
                        <DashboardLeftMenu />
                        <ChatListing chats={chats} updateChatListing={updateChatListing} activeChatId={currentChat?.chatId} />
                        {showChatWindow && <ParticipatingBots currentBots={currentBots} />}

                    </section>
                    <section className="right-block content">
                        {!showChatWindow && <BotSelector onStartChat={sendChat} />}
                        {showChatWindow && (
                            <div id="chat-window" className="box">
                                <ChatWindowHeader currentChat={currentChat} updateCurrentChat={updateCurrentChat} />
                                <ChatWindow currentChat={currentChat} conversationMessages={conversationMessages} onSendMessage={responseMessages} />
                                <ChatWindowFooter currentChat={currentChat} onSendMessage={responseMessages} />
                            </div>
                        )}
                    </section>


                </div>
            </div>
        </div>
    );
}

export default Dashboard;