/* global $ */
import React, { useEffect, useRef } from 'react';
import { nerdleGetChats } from '../services/nerdle_api';
import PropTypes from 'prop-types';

function ChatListing({ chats, updateChatListing, activeChatId }) {
    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) {
            return;
        }
        hasRun.current = true;
        const fetchData = async () => {
            try {
                const response = await nerdleGetChats();
                updateChatListing(response?.chats);
            } catch (error) {
                console.error("Unable to load chat", error.message);
            } 
        };

        fetchData();

        // Ensure jQuery is loaded
        if (window.$) {
            $('#chat-listing').slimScroll({
                height: '350px'
            });
        } else {
            console.error('jQuery is not loaded');
        }
    }, [updateChatListing]);

    function formatDate(date) {
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    }

    function formatChatName(name){
        if(name.length > 30) return name.substring(0, 30) + "...";
        else return name;
    }

    // Component render JSX
    return (
        <div className="box">
            <div className="box-header with-border">
                <h4 className="box-title">Chats</h4>
            </div>
            <div className="box-body no-padding mailbox-nav" id="chat-listing">
                <ul className="nav nav-pills flex-column">
                    {chats && chats.map(chat => (
                        <li key={chat.chatId} className={`nav-item`}>
                            <a className={`nav-link ${chat.chatId === activeChatId ? "active" : ""}`} href={`/chat/${chat.chatId}`}>
                                <i className="fa fa-circle-o text-info"></i> {formatChatName(chat.chatName)}
                                <br />
                                {formatDate(new Date(chat.modifiedDate))}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

// Type checking for props
ChatListing.propTypes = {
    updateChatListing: PropTypes.func.isRequired
};


export default ChatListing;