import { Link } from 'react-router-dom';

function Dashboard() {
    const styleUserHeader = {
        backgroundImage: 'url("/assets/images/user-info.jpg")'
    };

    return (
        <header className="main-header">
            <div className="d-flex align-items-center logo-box justify-content-start">
                <div className="logo">
                    <div className="logo-lg">
                        <span className="light-logo"><img src="/assets/images/logo/nerdle.svg" alt="logo" /></span>
                    </div>
                </div>
            </div>

            <nav className="navbar navbar-static-top">
                <div className="app-menu">
                </div>

                <div className="navbar-custom-menu r-side">
                    <ul className="nav navbar-nav">
                        <li className="btn-group nav-item d-md-none d-block d-lg-inline-block">
                            <Link href="#" data-provide="fullscreen" className="waves-effect waves-light nav-link full-screen" title="Full Screen">
                                <img src="/assets/images/svg-icon/fullscreen.svg" className="img-fluid svg-icon" alt="" />
                            </Link>
                        </li>

                        <li className="dropdown user user-menu">
                            <Link href="#" className="waves-effect waves-light dropdown-toggle" data-bs-toggle="dropdown" title="User">
                                <img src="/assets/images/svg-icon/user.svg" className="rounded svg-icon" alt="" />
                            </Link>
                            <ul className="dropdown-menu animated flipInX">
                                <li className="user-header bg-img" style={styleUserHeader} data-overlay="3">
                                    <div className="flexbox align-self-center">
                                        <img src="/assets/images/avatar/7.jpg" className="float-start rounded-circle" alt="Profile" />
                                        <h4 className="user-name align-self-center">
                                            <span>Samuel Brus</span>
                                            <small>samuel@gmail.com</small>
                                        </h4>
                                    </div>
                                </li>
                                <li className="user-body">
                                    <Link className="dropdown-item" href="#"><i className="ion ion-person"></i> My Profile</Link>
                                    <Link className="dropdown-item" href="#"><i className="ion ion-bag"></i> My Balance</Link>
                                    <Link className="dropdown-item" href="#"><i className="ion ion-email-unread"></i> Inbox</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" href="#"><i className="ion ion-settings"></i> Account Setting</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" href="#"><i className="ion-log-out"></i> Logout</Link>
                                    <div className="dropdown-divider"></div>
                                    <div className="p-10"><Link href="#" className="btn btn-sm btn-rounded btn-success">View Profile</Link></div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link href="#" data-toggle="control-sidebar" title="Setting" className="waves-effect waves-light">
                                <img src="/assets/images/svg-icon/settings.svg" className="img-fluid svg-icon" alt="" />
                            </Link>
                        </li>

                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Dashboard;