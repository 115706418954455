import React, { useState } from 'react';

function ChatWindowFooter({ onSendMessage }) {
    const [message, setMessage] = useState([]);

    const handleMessageSend = () => {
        onSendMessage(message);
        setMessage('');
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }; 

    // Component render JSX
    return (
        <div className="box-footer">
            <div className="d-md-flex d-block justify-content-between align-items-center">
                <textarea
                    className="form-control b-0 py-10"
                    type="text"
                    placeholder="Say something..."
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            handleMessageSend();
                        } else if (event.key === 'Enter' && event.shiftKey) {
                            event.preventDefault();
                            setMessage(message + '\n');
                        }
                    }}
                    value={message}
                    onChange={handleMessageChange} />
                <div className="d-flex justify-content-between align-items-center mt-md-0 mt-30">
                    <button type="button" className="waves-effect waves-circle btn btn-circle me-10 btn-outline-secondary">
                        <i className="fa fa-paperclip"></i>
                    </button>
                    <button type="button" className="waves-effect waves-circle btn btn-circle btn-primary" onClick={handleMessageSend}>
                        <i className="mdi mdi-send"></i>
                    </button>
                </div>
            </div>
        </div>

    );
}



export default ChatWindowFooter;