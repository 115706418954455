/* global $ */

import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { renameChat } from "../services/nerdle_api";

function ChatWindowHeader({ currentChat, updateCurrentChat }) {
  const [rename, setRename] = useState(false);
  const [chatName, setChatName] = useState("");

  useEffect(() => {
    if(currentChat) setChatName(currentChat.chatName);
  }, [currentChat]);

  const onBottomPageClick = () => {
    $('.chat-box-one').slimScroll({ scrollTo: $('.chat-box-one').prop('scrollHeight') + 'px' });
  }

  const onRenameChatClick = () => {
    setRename(true);
  }

  const onChatNameChange = (event) => {
    setChatName(event.target.value);
  }

  const onRenameChatAcceptClick = async () => {
    currentChat = await renameChat(currentChat.chatId, chatName);
    updateCurrentChat(currentChat);
    setRename(false);
  }

  const onRenameChatCancelClick = () => {
    setRename(false);
  }

  const onDeleteClick = () => {

  }

  return (
    <div className="box-header">
      <div class="modal center-modal fade" id="modal-delete" tabindex="-1" style={{ display: "none" }} aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete Chat</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>
                Are you sure you want to permanently delete this chat, <strong>"{currentChat?.chatName}"</strong>? This action cannot be undone and you will not be able to recover any of the chat data. Please confirm that you want to delete this chat.

              </p>
            </div>
            <div class="modal-footer modal-footer-uniform">
              <button type="button" class="btn btn-danger" click={onDeleteClick}>Yes, Delete</button>
              <button type="button" class="btn btn-primary float-end" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div className="media align-items-top p-0">
        <div className="d-lg-flex d-block justify-content-between align-items-center w-p100">
          <div className="media-body mb-lg-0 mb-20">
            <p className="fs-16">
              {!rename && (
                <div>
                  <button className="hover-primary no-border no-background-color"><i className="fa fa-star"></i></button>
                  <strong>{currentChat?.chatName}</strong>
                </div>
              )}
              {rename && (
                <div class="input-group" style={{ width: "30%" }}>
                  <input type="text" class="form-control" value={chatName} onChange={onChatNameChange} />
                  <span class="input-group-addon"><Link onClick={onRenameChatAcceptClick}><i class="fa fa-check"></i></Link></span>
                  <span class="input-group-addon"><Link onClick={onRenameChatCancelClick}><i class="fa fa-times"></i></Link></span>
                </div>
              )}
            </p>

          </div>

          <div className="d-flex justify-content-between align-items-center">
            <ul className="list-inline mb-0 fs-18">
              <li className="list-inline-item"><Link onClick={onRenameChatClick} className="hover-primary"><i className="fa fa-pencil"></i></Link></li>
              <li className="list-inline-item"><Link className="hover-primary" data-bs-toggle="modal" data-bs-target="#modal-delete"><i className="fa fa-trash"></i></Link></li>
              <li className="list-inline-item"><Link onClick={onBottomPageClick} className="hover-primary"><i className="fa fa-level-down"></i></Link></li>
            </ul>
            {/*<div className="lookup lookup-sm lookup-right d-none d-lg-block mx-10">
                <input type="text" name="s" placeholder="Search" />
            </div>*/}
          </div>

        </div>
      </div>
    </div>

  );
}

export default ChatWindowHeader;