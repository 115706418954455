import axios from 'axios';
import { io } from 'socket.io-client';

const API_BASE_URL = process.env.REACT_APP_API_URL;
let socket = null;

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token'); // Or get from sessionStorage or cookies
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export const nerdleLogin = async (postData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/user/login`, postData);
        return response.data;
    } catch (error) {
        return null;
    }
};

export const nerdleGetBots = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/bots/getBots`);
        return response.data;
    } catch (error) {
        return null;
    }
};

export const nerdleGetChats = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/chats/getChats`);
        return response.data;
    } catch (error) {
        return null;
    }
};

export const nerdleGetChat = async (chatId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/chats/getChat/${chatId}`);
        return response.data;
    } catch (error) {
        return null;
    }
};

export const renameChat = async (chatId, chatName) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/chats/renameChat/${chatId}?chatName=${encodeURIComponent(chatName)}`);
        return response.data;
    } catch (error) {
        return null;
    }
};

export const nerdleSetupSocketConnection = async (eventHandlers) => {
    const token = localStorage.getItem('token');

    socket = io(API_BASE_URL, {
        auth: {
            token: `${token}`  // Include the token here
        }
    });

    socket.on('connect_error', (error) => {
        console.log('Connection Error:', error);
    });

    for(const event in eventHandlers) {
        socket.on(event, eventHandlers[event]);
    }
    return socket;
};

export const nerdleSendSocketMessage = async (event, message) =>{
    socket.emit(event, message);
}

export const nerdleCloseSocketConnection = () =>{
    if(socket && socket.connected) socket.disconnect();
}