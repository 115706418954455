import React from 'react';


function DashboardLeftMenu() {
    // Component render JSX
    return (
        <div className="box">
            <div className="box-header with-border">
                <h4 className="box-title">Options</h4>
            </div>
            <div className="box-body no-padding mailbox-nav">
                <ul className="nav nav-pills flex-column">
                    <li className="nav-item"><a className="nav-link" href="# "><i className="fa fa-user-circle"></i> Create a bot</a></li>
                    <li className="nav-item"><a className="nav-link"  href="# "><i className="fa fa-terminal"></i> Bot Instructions</a></li>
                    <li className="nav-item"><a className="nav-link"  href="# "><i className="fa  fa-flash"></i> Plugins</a></li>
                </ul>
            </div>
        </div>
    );
}

export default DashboardLeftMenu;